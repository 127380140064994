@import "../../global.scss";

.topbar {
  width: 100%;
  height: 80px;
  background-color: white;
  color: $mainColor;
  position: fixed;
  top: 0;
  z-index: 3;
  transition: all 0.3s ease-in-out;

  .wrapper {
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;

      @include mobile {
        flex-direction: column;
        align-items: flex-start;
      }

      .logo {
        font-size: 49px;
        font-weight: 600;
        text-decoration: none;
        color: inherit;
        margin-right: 10px;


        @include mobile {
          font-size: 20px;
        }
      }

      .itemContainer {
        display: flex;
        align-items: center;
        margin-left: 20px;
        

        @include mobile {
          font-size: 20px;
          margin-left: 0;
          transition: all 0.3s ease-in-out;
        }

        .icon {
          font-size: 22px;
          margin-right: 5 px;
          margin-right: 10px;
          // transition: all 0.3s ease-in-out;

          @include mobile {
            font-size: 18px;
            transition: all 0.3s ease-in-out;
          }

          @include mid-width{
            font-size: 17px;
            transition: all 0.3s ease-in-out;
          }
        }

        a{
          text-decoration: none;
          color: inherit;
        }

        span {
          font-size: 20px;
          font-weight: 500;
          // transition: all 0.3s ease-in-out;

          @include mobile {
            font-size: 15px;
            transition: all 0.3s ease-in-out;
          }

          @include mid-width{
            font-size: 14px;
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }

    .right {
      display: flex;
      align-items: center;

      .link{
        img{
          width: 23px;
          height: 23px;
          border-radius: 50%;
          margin-right: 10px;
          // border: 1px solid $mainColor;
        }
      }
      .toggle {
        margin-right: 15px;
      }
      .hamburger {
        width: 32px;
        height: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;

        span {
          width: 100%;
          height: 3px;
          background-color: $mainColor;
          transform-origin: left;
          transition: all 0.3s ease-in-out;
        }
      }
    }

    &.active {
      // background-color: $menuColor;
      // color: white;
  
      .hamburger {
        span {
          &:first-child {
            // background-color: white;
            transform: rotate(45deg);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:last-child {
            // background-color: white;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }



  &.dark{
    background-color: $darkColor;
    color: $darkFont;

    .wrapper {
      .right{
        .toggle{
          transition: all 0.3s ease-in-out;
        }
      }
    }

    .hamburger {
      span {
        &:first-child {
          background-color: white;
          // transform: rotate(45deg);
        }
        &:nth-child(2) {
          background-color: white;
          // opacity: 0;
        }
        &:last-child {
          background-color: white;
          // transform: rotate(-45deg);
        }
      }
    }
  }
}
