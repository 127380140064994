@import "../../global.scss";

.menu {
  width: 200px;
  height: 100vh;
  background-color: white;
  position: fixed;
  top: 0;
  right: -200px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;

  @include mobile{
    width: 100vw;
    right: 0;
    top: -100vh;
    text-align: center;
  }

  &.active {
    right: 0;
    background-color: $menuColor;

    @include mobile {
      top: 0;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 25px;
    font-weight: 300;
    color: white;
    width: 80%;

    li {
      margin-bottom: 25px;
      a {
        font-size: inherit;
        color: inherit;
        text-decoration: none;
      }

      &:hover {
        font-weight: 500;
      }
    }
  }
}
