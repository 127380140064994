@import "../../global.scss";

.intro {
  background-color: white;
  display: flex;
  transition: all 0.3s ease-in-out;

  @include mobile {
    flex-direction: column;
    align-items: center;
  }

  .left {
    flex: 0.5;
    overflow: hidden;

    @include mobile {
      position: relative;
    }

    .imgContainer {
      width: 700px;
      height: 700px;
      background-color: crimson;
      border-radius: 50%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      float: right;

      @include mobile {
        align-items: flex-start;
      }
    }

    img {
      height: 90%;

      @include mobile {
        position: absolute;
        bottom: 0;
      }
    }
  }

  .right {
    flex: 0.5;
    // position: relative;
    // display: flex;
    // flex-direction: column;

    .wrapper {
      flex: 0.5;
      width: 100%;
      height: 100%;
      padding-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include mobile {
        padding-left: 0;
        align-items: center;
      }

      h1 {
        font-size: 60px;
        margin: 10px;

        @include mobile {
          font-size: 35px;
        }
      }

      h2 {
        font-size: 35px;
      }

      h3 {
        font-size: 30px;

        @include mobile {
          font-size: 20px;
        }

        span {
          font-size: inherit;
          color: crimson;
        }
        .ityped-cursor {
          animation: blink 1s infinite;
        }

        @keyframes blink {
          0% {
            opacity: 0;
          }
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
      }

      button {
        width: 150px;
        height: 30px;
        color: white;
        background-color: crimson;
        border: none;
        border-radius: 10px;
        margin-top: 10px;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  &.dark {
    background-color: $darkColor;
    color: $darkFont;
    .left {
      .imgContainer {
        background-color: #980f5a;
      }
    }
  }
}
