@import "../../global.scss";

.works {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease-in-out;
  // height: 100vh !important;
  @include mid-width {
    height: fit-content !important;
  }

  h1 {
    font-size: 45px;

    @include mobile {
      font-size: 30px;
    }
  }

  ul {
    margin: 10px;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    

    @include mobile {
      margin: 10px 0;
      flex-wrap: wrap;
      justify-content: center;
    }

    .worksList {
      font-size: 14px;
      margin: 0 10px 0 10px;
      padding: 7px;
      border-radius: 10px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &.active {
        background-color: $mainColor;
        color: white;
      }
    }
  }

  .container {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    

    @include mobile {
      width: 100%;
      overflow: hidden;
    }
    .MuiPaper-root {
      width: 270px;
      // width: 345px;
      height: 180px;
      margin: 10px;
      border-radius: 10px;
      transition: all 0.5s ease-in-out;

      // @include mid-width {
      //   width: 210px;
      //   height: 140px;
      // }

      // @include mobile{
      //   width: 150px;
      //   height: 100px;
      // }

      img {
        width: 100%;
        height: 100px;
        // border-radius: 20px;
        object-fit: cover;
        z-index: 1;
      }

      // &:hover {
      //   background-color: $mainColor;

      //   img {
      //     opacity: 0.2;
      //     z-index: 0;
      //   }
      // }
      // }
    }
  }

  &.dark {
    background-color: $darkColor;
    color: $darkFont;
  }
}

.box-container {
  &.dark {
    background-color: $darkColor;
    color: $darkFont;
  }

  margin: 10px;
  display: flex;
  flex-direction: column;
  .box-img {
    img {
      width: 100%;
      height: 100%;
      border-radius: 20px;
      object-fit: cover;
    }
  }
  .box-section {
    display: flex;
    flex-direction: row;
    .box-left {
      margin: 10px;
      flex-wrap: wrap;
      width: 30%;
      display: flex;
      flex-direction: column;
      @include mobile {
        h3 {
          font-size: 12px;
        }
      }
      .MuiChip-root {
        margin: 5px;
        @include mobile {
          width: 100%;
          margin: 5px 0 5px 0;
          span {
            font-size: 12px;
          }
        }
      }
    }
    .box-right {
      // display: flex;
      // flex-direction: row;
      margin: 10px;
      width: 70%;
      @include mobile {
        ul {
          padding: 0;
        }
      }
    }
  }
  .box-button {
    .MuiButton-root {
      float: right;
    }
  }
}

.MuiPaper-root {
  max-width: 1280px !important;
  width: 80vw;
  .MuiDialogContent-root {
    @include mobile {
      padding: 0;
    }
  }
}
