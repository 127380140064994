@import "../../global.scss";

.skills {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  h1 {
    position: absolute;
    top: 10px;
    font-size: 50px;

    @include mobile {
      font-size: 30px;
    }
  }

  .skills-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    .skill {
      text-align: center;
      img {
        width: 140px;
        height: 140px;
        margin: 14px;
        @include mid-width {
          width: 120px;
          height: 120px;
          margin: 12px;
        }
        @include mobile {
          width: 100px;
          height: 100px;
          margin: 10px;
        }
      }
      h2 {
        font-size: 40px;
        margin: 20px;
        text-align: center;
        @include mid-width {
          font-size: 30px;
          margin: 15px;
        }
        @include mobile {
          font-size: 20px;
          margin: 10px;
        }
      }
    }
  }

  .arrow {
    height: 70px;
    position: absolute;
    cursor: pointer;

    @include mobile {
      display: none;
    }

    &.left {
      left: 3vw;
      transform: rotate(180deg);
    }

    &.right {
      right: 3vw;
      transform: rotate(0deg);
    }
  }

  .slider {
    height: 350px;
    display: flex;
    position: absolute;
    left: 0;
    transition: all 0.5s ease;

    @include mobile {
      height: 80vh;
      flex-direction: column;
      justify-content: center;
    }

    .container {
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: center;

      .item {
        width: 700px;
        height: 100%;
        background-color: white;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile {
          width: 80%;
          height: 22vh;
          margin: 15px 0;
        }

        .left {
          flex: 4;
          height: 80%;
          display: flex;
          align-items: center;
          justify-content: center;

          .leftContainer {
            width: 90%;
            height: 70%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .imgContainer {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: rgb(245, 179, 155);

              @include mobile {
                width: 20px;
                height: 20px;
              }

              img {
                width: 25px;

                @include mobile {
                  width: 15px;
                }
              }
            }

            h2 {
              font-size: 20px;

              @include mobile {
                font-size: 13px;
              }
            }

            p {
              font-size: 15px;

              @include mobile {
                display: none;
              }
            }

            span {
              font-size: 12px;
              font-weight: 600;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
        .right {
          flex: 8;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;

          img {
            width: 400px;
            transform: rotate(-10deg);
          }
        }
      }
    }
  }
  &.dark {
    background-color: $darkColor;
    color: $darkFont;
    .slider {
      .container {
        .item {
          background-color: $darkColor;
        }
      }
    }
  }
}
