$mainColor: #15023a;

$menuColor: rgba(0, 0, 0, 0.736);

$darkColor: #2b2348;

$darkFont: #d9e2ec;

$width: 768px;

@mixin mobile {
  @media (max-width: $width) {
    @content;
  }
}

$mid-width: 966px;

@mixin mid-width {
  @media (max-width: $mid-width) {
    @content;
  }
}

$large-width: 1024px;

$xl-width: 1280px;
@mixin xl-width {
  @media (max-width: $xl-width) {
    @content;
  }
}

$xxl-width: 1623px;
@mixin xxl-width {
  @media (max-width: $xxl-width) {
    @content;
  }
}
