@import "global";
.app {
  height: 100vh;
  &.dark {
    background-color: $darkColor;
    color: $darkFont;
  }

  .sections {
    width: 100%;
    left: calc(50vw - 640px);
    max-width: 1280px;
    height: calc(100vh - 80px);
    background-color: white;
    position: relative;
    top: 80px;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    scrollbar-width: none; // for firefox
    &::-webkit-scrollbar {
      display: none;
    } //for chrome and safari

    @include xl-width {
      left: 0;
    }

    > * {
      // width: 100vw;
      max-width: 1280px;
      height: calc(100vh - 70px);
      // scroll-snap-align: start;
    }
  }
}
